.post_navigation {
    // display: flex;
    // justify-content: center;
    padding: 40px 0;
    background: $color-bg--neutral;

    .return {
        a {
            position: relative;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            color: $color-text;
            transition: all $duration;

            svg {
                width: 26px;
                height: 26px;
                margin-right: 5px;
                fill: $color-text;
                transition: all $duration;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: -3px;
                left: 0;
                width: 0;
                height: 2px;
                background-color: $color-text;
                transition: width ease-in-out $duration;
            }
    
            &:hover, &:focus, &:focus-within {
                color: $color-text;
    
                &::after {
                    width: 101%;
                    transition: width ease-in-out $duration;
                }
            }
        }
    }
}

// .navigation_link svg {
//     position: relative;
// }// Update Upgrades 11/2023
// for all buttons navigations
.navigation_link {
    column-gap: 5px;
}

.post_navigation {
    &--container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        // Si les liens sont cachés, mettre ce style :
        // justify-content: space-evenly;
        width: 100%;
        gap: 30px;
    }

    .return {
        a {
            align-items: center;
        }
    }

    .prev, .next {
      // A adapter en fonction de la width du bouton précédent
      position: relative;
      min-width: 110px;

        .navigation_link {
            color: $color-text;

            // Recup style ~ ligne 9
            a {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 700;
                transition: color 0.25s;
            }
        }
    }

    .prev {
        .navigation_link {
            a {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-text;
                    mask-image: url( $urlShort + '/ic-nav-arrow-left.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-right: 5px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -3px;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background-color: $color-text;
                    transition: width ease-in-out $duration;
                }
        
                &:hover, &:focus, &:focus-within {
                    color: $color-text;
        
                    &::after {
                        width: 101%;
                        transition: width ease-in-out $duration;
                    }
                }
            }
        }
    }

    .next {
        .navigation_link {
          // Si il y a un background sur le navigation_link mettre sur .next
             display: flex;
            justify-content: flex-end;

          a {
                &:after {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-text;
                    mask-image: url( $urlShort + '/ic-nav-arrow-right.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-left: 5px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    bottom: -3px;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background-color: $color-text;
                    transition: width ease-in-out $duration;
                }
        
                &:hover, &:focus, &:focus-within {
                    color: $color-text;
        
                    &::before {
                        width: 101%;
                        transition: width ease-in-out $duration;
                    }
                }
            }
        }
    }

    .prev, .next {
        &:hover, &:focus, &:focus-within {

            .navigation_link {
                color: $color-text;

                svg {
                    fill: $color-text;
                }

                a {
                    &:before, &:after {
                        background-color: $color-text;
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .post_navigation {
        .return {
            display: flex;
            justify-content: center;
            order: 1;
            flex: 1 1 100%;
        }
    }
}
