//======================================================================================================
// Style Home
//======================================================================================================
.home {

    &__title {
        margin: 0 0 30px 0;
        padding: 0;
        border: 0;
        text-align: center;
        font-size: $font-size--2;
        color: $color-text;

        &--white {
            color: $color-white;
        }

        &--second {
            color: $color-second;
        }
    }

    &__buttons {
        display: flex;
        align-content: center;
        justify-content: center;
        margin: 30px 0 0 0;

        .button {
            min-width: 270px;
            margin: 0 15px;
            padding: 12px 30px;
        }
        
    }

    .button--white {
        background-color: $color-white;
        border-color: $color-white;
        color: $color-text;

        &:hover, &:focus {
            background-color: $color-bg--transparent;
            border-color: $color-white;
            color: $color-white;
        }
    }
  
  
    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .home-slideshow {
        position: relative;
        overflow: hidden;
  
        .swiper-container {
            height: 500px;
            width: 100%;
            z-index: inherit;
    
            .swiper-slide {
                img {
                    position: absolute;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    object-position: top;
                }
            }
  
            .swiper-pagination {
                position: absolute;
                left: 0;
                right: 0;
                width: auto;
                height: auto;
                bottom: 25px;
                z-index: 2;
                pointer-events: auto;
  
                .swiper-pagination-bullet {
                    position: relative;
                    margin: 0 7.5px;
                    width: 10px;
                    height: 10px;
                    background: $color-white;
                    border-radius: 100px;
                    margin: 0 5px;
                    transition: $duration ease-in-out;
                    transform-origin: 50% 50%;
                    filter: drop-shadow(0px 0px 10px #000000);
                    opacity: 1;
                }

                .swiper-pagination-bullet-active {
                    width: 40px;
                    background: $color-main;
                }
            }
        }
  
        .slideshow {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;

            .container--slideshow {
                position: relative;
                display: flex;
                justify-content: center;
                height: 100%;
            }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                box-sizing: border-box;
                position: absolute;
                width: 630px;
                height: auto;
                bottom: 65px;
                padding: 20px 30px;
                background-color: $color-black--rgba;
                border-radius: $border-radius;
                text-align: center;
                color: $color-white;
            }

            &__title {
                font-family: $font-family--heading;
                font-weight: $font-weight--heading;
                line-height: $line-height--heading;
                font-size: $font-size--3;
            }

            &__description {
                margin-top: 10px;
            }
        }

        .video-container {
            height: 500px;
            width: 100%;
    
            .slideshow {
                display: inherit;
                position: relative;
                width: 100%;
                height: 100%;

                video,
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    object-position: top;
                }

                img {
                    display: none;
                }
            }
        }
    }
  
  
    //======================================================================================================
    // Access (admin-area-1)
    //======================================================================================================
    .access {
        position: relative;
        background-color: $color-main;
        padding: 50px 0;
  
        &__list {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
  
        &__link {
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            border-radius: $border-radius;
            font-size: $font-size--text_small;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            line-height: 1.1;
  
            .access__image {
                width: 170px;
                height: 108px;
                margin-bottom: 62px;
                background-color: $color-white;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
  
            &:hover, &:focus {
                .access__content {
                    height: 100%;
                    transition: height ease-in-out $duration;
                }

            }
        }

        &__content {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 62px;
            padding: 15px;
            background-color: $color-dark;
            text-align: center;
            color: $color-white;
            line-height: 1.3;
            transition: height ease-in-out $duration;
        }
  
    }
  
  
    //======================================================================================================
    // Events
    //======================================================================================================
    .events {
        position: relative;
        padding: 70px 0;
        background-color: $color-bg--neutral;

        &__container {
            position: relative;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
        }

        &__item {
            position: relative;
            display: flex;
            flex: 1 1 48%;
            margin-left: 30px;
            margin-bottom: 30px;
            padding: 10px;
            background-color: $color-white;
            border-radius: $border-radius;
            overflow: hidden;
            transition: all ease-in-out $duration;

            &:first-child, &:nth-child(3) {
                margin-left: 0;
            }

            &:nth-child(3), &:nth-child(4) {
                margin-bottom: 0;
            }

            &:hover, &:focus {
                background-color: $color-dark;
                color: $color-white;
                transition: all ease-in-out $duration;
            }
        }

        &__link {
            display: flex;
            align-items: stretch;

            &:hover, &:focus {
                color: $color-white;
            }
        }

        &__date {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 15px 20px;
            background-color: $color-main;
            border-radius: $border-radius;
            color: $color-second;

            p {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 50px;
            }

            &__day {
                font-size: 2.188rem;
                line-height: 1.1;
                font-weight: $font-weight-bold;
            }

            &__month {
                margin-top: 5px;
                font-size: 1rem;
                font-weight: $font-weight-medium;
                line-height: 1.1;
                text-transform: uppercase;
            }

            svg {
                position: relative;
                width: 50px;
                height: 25px;
                fill: $color-second;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            padding: 35px;

            &__title {
                margin: 0;
            }
        }

    }


    //======================================================================================================
    // News (posts)
    //======================================================================================================
    .news {
        position: relative;
        padding: 70px 0;
        background-color: $color-dark;
  
        &__container {
            position: relative;
        }
  
        &__list {
            display: flex;
            align-items: stretch;

            &--small {
                flex: 1 1 48%;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                margin-left: 15px;
                padding-bottom: 45px;

                .news__info {
                    min-height: 150px;
                }
            }
        }
  
        &__item {
            width: 570px;
            margin-bottom: 30px;
            border-radius: $border-radius;
            overflow: hidden;
            color: $color-text;
    
            &.news__item--big {
                flex: 1 1 50%;
                position: relative;
                min-height: 400px;
                margin-bottom: 0;
                margin-right: 15px;
  
                .news__info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: 0;
                    background-color: $color-main--rgba2;
                    color: $color-second;
                    transition: all ease-in-out $duration;
                }
        
                &:hover, &:focus-within {
                    .news__info {
                        background-color: $color-main;
                        padding: 45px 30px;
                        transition: all ease-in-out $duration;
                    }
                }
            }
  
            &:hover, &:focus-within {
                color: $color-second;

                .news__link {
                    color: $color-second;
                }
            }
        }

        &__link {
            display: block;
            height: 100%;
            border-radius: $border-radius;
            background-color: $color-white;
            color: $color-text;

            &:hover, &__link:focus {
                background-color: $color-main;
                color: $color-second;
            }
        }
  
        &__content {
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }

            &--notimg {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) no-repeat $color-gray;
                background-position: 50% 30%;
            }
        }
  
        &__info {
            box-sizing: border-box;
            padding: 30px;
    
            &__title {
                font-size: $font-size--4;
                margin: 0 0 10px 0;
            }
        }

        &__buttons {
            position: absolute;
            right: 15px;
            bottom: 0;
            margin: 0;

            .button {
                margin: 0;
                width: 570px;

                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                    border-color: $color-white;
                    color: $color-white;
                }

                &:last-child {
                    margin-left: 30px;
                }

                &--white {
                    &:hover, &:focus {
                        background-color: $color-bg--transparent;
                        border-color: $color-white;
                        color: $color-white;
                    } 
                }
            }
        }
  
    }
  
  
    //======================================================================================================
    // Docs (Kiosque)
    //======================================================================================================
    .kiosque {
        position: relative;
        padding: 70px 0;
        background-color: $color-white;

        &__list {
            display: flex;
            align-items: center;
        }

        &__item {
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
            width: 50%;

            &:first-child {
                flex-direction: row-reverse;
                padding-right: 15px;
                text-align: right;

                .kiosque {
                    &__buttons {
                        align-items: flex-end;
                    }

                    &__btn {
                        flex-direction: row-reverse;

                        svg {
                            margin-right: 0;
                            margin-left: 10px;
                        }
                    }
                }
            }

            &:last-child {
                padding-left: 15px;

                .kiosque {
                    &__content {
                        margin-right: 0;
                        margin-left: 30px;
                    }

                    &__buttons {
                        align-items: flex-start;
                    }
                }
            }
        }

        &__title {
            margin: 0 0 20px 0;
        }

        &__image {
            display: block;
            background-color: $color-main;
            width: 189px;
            height: 267px;
            overflow: hidden;
            border-radius: $border-radius;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }

            &--no-image {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-bg--image;

                svg {
                    width: 40%;
                }
            }
        }

        &__content {
            display: block;
            width: 350px;
            margin-right: 30px;
        }

        &__filesize {
            text-transform: uppercase;
        }

        &__buttons {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
        }

        &__btn {
            margin: 10px 0;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            text-decoration: none;

            &:hover, &:focus {
                svg {
                    background-color: $color-dark;
                    fill: $color-white;
                }
            }
        }


    }


    //======================================================================================================
    // Social Wall
    //======================================================================================================
    .social-wall {
        position: relative;
        padding: 70px 0;
        background-color: $color-main;

        .ff-stream {
            min-height: 440px !important;
        }

        #ff-stream-1, #ff-stream-1 .ff-popup, #ff-stream-1 .ff-search input {
            padding: 0;
            background-color: $color-main !important;
        }
        #ff-stream-1 .ff-header {
            display: none;
            z-index: 0;
        }
        .ff-stream-wrapper {
            margin: 0;
        }
        #ff-stream-1 .ff-filter:hover, 
        #ff-stream-1 .ff-filter.ff-filter--active, 
        #ff-stream-1 .ff-moderation-button, 
        #ff-stream-1 .ff-loadmore-wrapper .ff-btn, 
        #ff-stream-1 .ff-square:nth-child(1) {
            min-width: 270px;
            margin: 30px 15px 0 15px;
            padding: 12px 30px;
            border: $btn-border-width $btn-border-style $color-dark;
            border-radius: $btn-border-radius;
            background-color: $color-dark;
            color: $color-white;
            font-size: $btn-font-size;
            font-weight: $btn-font-weight;
            font-family: $btn-font-family;
            line-height: $btn-line-height;
            transform: $btn-text-transform;
            transition: all ease-in-out $duration;

            &:hover, &:focus {
                background-color: $color-bg--transparent;
                border-color: $color-second;
                color: $color-second;
                transition: all ease-in-out $duration;
            }
        }
        .ff-pinterest .ff-icon-inner {
            background-color: #BD081C;
        }
        .ff-facebook .ff-icon-inner {
            background-color: #1877F2;
        }
        .ff-twitter .ff-icon-inner {
            background-color: #1DA1F2;
        }
        .ff-instagram .ff-icon-inner {
            background-color: #E4405F;
        }
        .ff-youtube .ff-icon-inner {
            background-color: #FF0000;
        }

        .ff-item a {
            color: $color-text;
        }
    }
  

    //======================================================================================================
    // Zoom (admin-area-2)
    //======================================================================================================
    .zoom {
        position: relative;
        padding: 70px 0;
        background-color: $color-dark;

        &__list {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
        }

        &__item {
            position: relative;
            flex: 1 1 0;
            margin: 0 15px;
            overflow: hidden;
            border-radius: $border-radius;

            &:first-child {                
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &__link {
            display: block;
            position: relative;
            background-color: $color-main;
            color: $color-second;
            text-decoration: none;

            &:hover, &:focus {
                .zoom__content {
                    background-color: $color-main;
                    transition: background-color ease-in-out $duration;
                }
            }
        }

        &__block {
            display: block;
            position: relative;
            background-color: $color-main;
            color: $color-second;
        }

        &__image {
            height: 150px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                //object-position: top;
            }
        }

        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $color-main--rgba2;
            z-index: 1;
            transition: background-color ease-in-out $duration;
        }

        &__title {
            font-family: $font-family--heading;
            font-size: $font-size--4;
            font-weight: $font-weight--heading;
        }

    }


} 
  
  
//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .home {

        // Style home 
        &__buttons { 
            .button {
                margin: 0 10px;
            }
        }

        // Access (admin-area-1)
        .access {

            &__link {
                .access__image {
                    width: 140px;
                    height: 90px;
                    margin-bottom: 50px;
                }
            }

            &__content {
                height: 50px;
                padding: 10px;
            }
        }

        // Events 
        .events {
            &__item {
                margin-bottom: 20px;
                margin-left: 20px;
            }
        }

        // News (posts)
        .news {
            &__item {
                width: 460px;

                &:last-child {
                    margin-bottom: 0;
                }

                .news__item--big {
                    margin-right: 10px;
                }
            }

            &__list--small {
                margin-left: 10px;
                padding-bottom: 0;
            }

            &__buttons {
                position: relative;
                right: auto;
                bottom: auto;
                margin-top: 30px;

                .button {
                    margin: 0 10px;
                    width: auto;

                    &:last-child {
                        margin-left: 10px;
                    }
                }
            }
        }

        // Kiosque 
        .kiosque { 
            &__item {
                &:first-child {
                    padding-right: 10px;
                }

                &:last-child {
                    padding-left: 10px;

                    .kiosque__content {
                        margin-left: 20px;
                    }
        
                }
            }

            &__image {
                width: 140px;
                height: 200px;
            }

            &__content {
                width: 300px;
                margin-right: 20px;
            }

            &__title {
                margin: 0 0 10px 0;
                font-size: $font-size--4;
            }

            &__buttons {
                margin-top: 5px;
            }

            &__btn {
                margin: 0 0 8px 0;
            }
        }

        // Zoom (admin-area-2)
        .zoom {
            &__item {
                width: 300px;
                margin: 0 10px;
            }
        }

    }

}


// 960
@media screen and (max-width: $medium) {

    .home {

        // Style home 
        &__buttons { 
            justify-content: space-between;
            .button {
                min-width: 48%;
                margin: 0;
            }
        }

        // Slideshow
        .home-slideshow  {
            position: relative;

            .swiper-container, .video-container {
                height: 360px;
            }
            .video-container {
                .slideshow {
                    video {
                        display: none;
                    }
                    img {
                        display: block;
                    }
                }
            }
        }

        // Access (admin-area-1)
        .access {

            &__list {
                flex-wrap: wrap;

                li {
                    &:first-child, &:nth-child(2), &:nth-child(3) {
                        margin-bottom: 55px;
                    }
                }
            }

            &__link {
                .access__image {
                    width: 170px;
                    height: 108px;
                    margin-bottom: 62px;
                }
            }

            &__content {
                height: 62px;
                padding: 15px;
            }
        }

        // Events 
        .events {
            &__item {
                flex: 1 1 100%;
                width: 100%;
                min-height: 170px;
                margin-bottom: 20px;
                margin-left: 0;

                &:nth-child(3) {
                    margin-bottom: 20px;
                }
            }
        }

        // News (posts)
        .news {

            &__list {
                flex-wrap: wrap;
            }
            
            &__item {
                width: 100%;
                margin: 0 0 20px 0;

                &:last-child {
                    margin-bottom: 0;
                }

                &.news__item--big {
                    flex: 1 1 100%;
                    margin: 0 0 20px 0;
                }
            }

            &__list--small {
                flex: 1 1 100%;
                margin-left: 0;
                padding-bottom: 0;
            }

            &__buttons .button {
                margin: 0;

                &:last-child {
                    margin-left: 0;
                }
            }

        }

        // Kiosque 
        .kiosque { 

            &__list {
                flex-wrap: wrap;
            }

            &__item {
                width: 100%;

                &:first-child {
                    flex-direction: row;
                    padding-right: 0;
                    margin-bottom: 30px;

                    .kiosque__content {
                        margin-left: 30px;
                        text-align: left;
                    }

                    .kiosque__buttons {
                        align-items: flex-start;
                    }

                    .kiosque__btn {
                        flex-direction: row;

                        svg {
                            margin-right: 10px;
                            margin-left: 0;
                        }                        
                    }
                }

                &:last-child {
                    padding-left: 0;

                    .kiosque__content {
                        margin-left: 30px;
                    }
        
                }
            }

            &__image {
                width: 189px;
                height: 267px;
            }

            &__content {
                width: 400px;
                margin-right: 0;
            }

            &__title {
                margin: 0 0 15px;
                font-size: $font-size--3;
            }

            &__btn {
                margin: 10px 0;
            }
        }

        // Zoom (admin-area-2)
        .zoom {
            &__list {
                flex-wrap: wrap;
                justify-content: center;
            }

            &__item {
                flex: initial;
                margin: 10px;

                &:nth-child(2) {
                    margin-right: 0;
                }
            }
        }

    }

}


// 640
@media screen and (max-width: $small) {

    .home {

        // Style home 
        &__buttons { 
            flex-wrap: wrap;

            .button {
                min-width: 100%;
                margin: 0;

                &:first-child {
                    margin-bottom: 20px;
                }
            }
        }

        // Slideshow
        .home-slideshow  {
            .swiper-container, .video-container {
                height: 260px;

                .swiper-pagination {
                    bottom: 15px;
                }
            }
            .slideshow__content {
                width: 300px;
                bottom: 50px;
            }
        }

        // Access (admin-area-1)
        .access {

            &__list {
                li {
                    margin-bottom: 20px;

                    &:first-child, &:nth-child(2), &:nth-child(3) {
                        margin-bottom: 20px;
                    }

                    &:nth-child(5), &:nth-child(6) {
                        margin-bottom: 0;
                    }
                }
            }

            &__link {
                .access__image {
                    width: 140px;
                    height: 90px;
                    margin-bottom: 50px;
                }
            }

            &__content {
                height: 50px;
                padding: 10px;
            }
        }

        // Events 
        .events {
            &__info {
                padding: 5px 5px 5px 15px;
            }
            &__date {
                padding: 15px;

                p, svg {
                    width: 30px;
                }
            }
            
        }

        // News (posts)
        .news {
            &__content {
                flex-wrap: wrap;

                img {
                    height: 215px;
                }
            }

            &__info {
                padding: 20px;
            }
            
            &__item {
                &.news__item--big {
                    height: auto;
                    min-height: auto;

                    &:hover, &:focus {
                        .news__info {
                            padding: 20px;
                        }
                    }

                    .news__info {
                        position: relative;
                        left: auto;
                        right: auto;
                        top: auto;
                        bottom: auto;
                        background-color: $color-main;
                    }
                }
            }
        }

        // Kiosque 
        .kiosque {
            &__item {
                justify-content: center;

                &:first-child {
                    margin-bottom: 40px;

                    .kiosque__content {
                        margin-left: 0;
                        text-align: center;
                    }

                    .kiosque__buttons {
                        flex-direction: row;
                        justify-content: center;
                    }

                    .kiosque__btn svg {
                        margin-right: 0;
                    }
                }

                &:last-child {
                    .kiosque__content {
                        margin-left: 0;
                    }
                }
            }

            &__image {
                margin-bottom: 20px;
            }

            &__content {
                width: 100%;
                margin: 0;
                text-align: center;
            }

            &__title {
                margin: 0 0 10px;
            }

            &__buttons {
                flex-direction: row;
                justify-content: center;
                margin-top: 20px;
            }

            &__btn {
                margin: 0 10px;

                span {
                    display: none;
                }

                svg {
                    margin: 0;
                }
            }
        }

        // Zoom (admin-area-2)
        .zoom {
            &__item {
                width: 100%;
                margin: 10px 0;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

}
