.card {
    width: 100%;
    margin-bottom: 15px;

    &__container {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-content: stretch;
        min-height: 160px;
        padding: $card--padding;
        background: $color-bg--card;
        border-radius: $border-radius;
        border: 2px solid $color-bg--transparent;
        color: $color--card;
        transition: all $duration ease-in-out;

        // Events v1
        &--event-v1 {
            height: 240px;
            padding: $card--padding;

            .card__content {
                padding: $card-content--padding;
            }
        }

        &--search {
            min-height: 60px;

            .card__head-content {
                display: flex;
                align-items: center;

                .card__title {
                    margin: 0;
                    transition: all $duration ease-in-out;
                }
                .card__category {
                    margin-left: 10px;
                }
            }

            .card__description {
                margin-top: 10px;
            }

        }

    }

    &--document {
        margin-bottom: 20px;

        &.col-12 {
            padding-bottom: 0;
        }

        .button--secondary {
            margin-top: 20px;
        }
    }

    &--category {
        margin: 20px 0;
    }

    &__image-wrapper {
        width: 350px;
        height: 200px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            transition: transform 0.65s ease-in-out;
        }
    }

    &:hover, &:focus {
        .image-wrapper img {
            transform: scale(1.1);
        }

        .card__container {
            border-color: $color-main--rgba2;
            
            &.card__container--document {
                border-color: $color-bg--transparent;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        position: relative;
        padding: $card-content--padding;

        &--document {
            display: block;
            color: $color--card;
        }

        &--category {
            display: flex;
            align-items: center;
            flex-direction: row;
            padding: 0;

            .card__content {
                padding-left: 20px;
            }

            h3 {
                margin-top: 0;
                margin-bottom: 0;
                color: $color--card;

                span  {
                    display: block;
                    font-weight: $font-weight-medium;
                    font-size: $font-size--text;
                    margin-bottom: 5px;
                }
            }

            .number_posts {
                margin-top: 15px;
                font-size: $font-size--text-small;
                color: $color-second--card;
            }
        }
    }

    &__infos {
        display: flex;
        flex-wrap: wrap;
    }

    &__category {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: $font-size--text-xsmall;
        color: $color--taxo;
        text-transform: uppercase;
        background: $color-bg--taxo;
        padding: 3px 15px;
        border-radius: $btn-tax-border-radius;
    }

    &__actions {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-bg--icons;
        border-radius: 100%;
        transition: background-color $duration ease-in-out;

        svg {
            width: 26px;
            height: 26px;
            fill: $color-second--card;
            transition: fill $duration ease-in-out;
        }

        &:hover {
            background: $color-main;

            svg {
                fill: $color-white;
            }
        }
    }

    &__title {
        text-transform: none;
        font-family: $font-family--heading;
        font-size: $font-size--4;
        color: $color--card;
        border: none;
        margin: 15px 0 10px;
        padding-bottom: 0;
    }

    &__description {
        color: $color--card;

        ul {
            list-style-type: circle;
            padding-left: 15px;
        }
    }

    &__publication-date {
        display: flex;
        align-items: center;
        color: $color-second--card;
        margin: 15px 0 0 0;

        svg {
            width: 24px;
            height: 24px;
            margin-right: 10px;
            fill: $color-second--card;
        }
    }

    &__location {
        display: flex;
        align-items: center;
        margin-top: 15px;

        p {
            flex: 1;
            color: $color-second--card;
        }

        svg {
            width: 26px;
            height: 26px;
            fill: $color-second--card;
            margin: 0 6px 0 0;
        }
    }


    //=================================================================================================
    // Image par défaut
    //=================================================================================================
    .image-wrapper {
        background-repeat: no-repeat;
        background-position: center;

        &.post,
        &.category {
            background-image: url(/wp-content/themes/noyau/assets/images/icon-post.png);
        }

        &.events,
        &.events_category {
            background-image: url(/wp-content/themes/noyau/assets/images/icon-events.png);
        }

        &.page,
        &.transitions {
            background-image: url(/wp-content/themes/noyau/assets/images/icon-page.png);
        }

        &.documents,
        &.documents_category {
            background-image: url(/wp-content/themes/noyau/assets/images/icon-documents.png);
        }

        &.contacts,
        &.contacts_category {
            background-image: url(/wp-content/themes/noyau/assets/images/icon-contacts.png);
        }
    }


    //===========================
    // Events
    //===========================
    &__date {
        display: flex;
        align-items: stretch;
        margin-right: 20px;
        background-color: $color-bg--date-v1;
        border-radius: $border-radius;

        p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100px;
            color: $color-second;
        }

        &__many {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 5px 0 7px 0;
        }      

        &_day {
            font-family: $font-family--heading;
            font-weight: $font-weight--heading;
            font-size: $font-size--2;
            line-height: $line-height--heading;
        }

        &_month {
            font-size: $font-size--text-small;
            text-transform: uppercase;
        }

        svg {
            box-sizing: content-box;
            width: 26px;
            height: 26px;
            fill: $color-second;
        }
    }


    //=================================================================================================
    // Squared cards appearance
    //=================================================================================================
    &--square {
        .card {
            &__container {
                align-content: flex-start;
                height: 420px;
            }

            &__image-wrapper {
                width: 100%;
                height: $card-imageH;
                min-height: 0;
            }

            &__content {
                position: initial;
                display: flex;
                flex-direction: column;
                padding: 0 $card-content--padding $card-content--padding $card-content--padding;
            }

            &__title {
                margin: 10px 0;
            }

            &__infos {
                justify-content: center;
                transform: translateY(-50%);
            }

            &__actions {
                top: 10px;
                right: 10px;
            }


            //===========================
            // Events
            //===========================
            &__date {
                position: absolute;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0;  
                background-color: $color-bg--date-v2;  
                border-radius: 0 0 $border-radius $border-radius;

                p {
                    width: 100px;
                    height: 100px;
                    color: $color-second;
                    padding: 15px 0;
                }

                svg {
                    transform: rotate(-90deg);
                    box-sizing: inherit;
                    padding: 0;
                }
            }
        }
    }
}


//=================================================================================================
// Flexible ? Slider ?
//=================================================================================================
.card-embed-wrapper {
    margin: $gl-gutter-vertical 0;

    .swiper-container {
        padding: 10px;
        margin: -10px;

        [class*="col-"] {
            padding-bottom: 0;
        }
    }

    .swiper-pagination {
        position: relative;
        margin-top: 30px;
        bottom: 0;
    }

    .swiper-pagination-bullet,
    .swiper-pagination-bullet-active {
        width: 10px;
        height: 10px;
        background: $color-main;
        border-radius: $border-radius;
        margin: 0 5px;
        transition: $duration ease-in-out;
        transform-origin: 50% 50%;
        opacity: 0.8;

        &:hover {
            opacity: 1;
        }
    }

    .swiper-pagination-bullet-active {
        width: 40px;
        opacity: 1;
    }
  }


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .card {
        margin-bottom: 10px;

        &__image-wrapper {
            width: 280px;
        }

        &--square {
            .card  {
                &__container  {
                    height: 470px;
                }
            }
        }

    }

}


// 960
@media screen and (max-width: $medium) {

    .card {
        margin-bottom: 0;
        
        &:not(.card--square):not(.card--category):not(.card--portraits) {
            
            .card__image-wrapper:not(.events) {
                display: none;
            }

            .card__image-wrapper.events {
                background: none;
                width: auto;

                img {
                    display: none;
                }
            }

            .card__date {
                position: relative;
                margin: 0 20px 0 0;

                p {
                    width: 85px;
                }
            }

            // .card__content {
            //     padding: 0;
            // }

        }

        &__container--event-v1 {
            height: 260px;

            .card__date {
                padding: 0 5px;
            }
        }
    }

    .card-embed-wrapper {
        .swiper-container {
            [class*=col-]:last-child {
                padding-top: 15px;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .card  {

        &__image-wrapper {
            width: 270px;
        }

        &__container {
            min-height: auto;

            &--document {
                min-height: 0;
                
                .document-details {
                    flex-direction: column;
                    align-items: flex-start;

                    .content-right {
                        margin-top: 15px;

                        a:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        &__title {
            font-size: 1.188rem;
        }

        &:not(.card--square):not(.card--category):not(.card--portraits) {

            .card__image-wrapper.events {
                background: none;
                width: 100%;
                height: 70px;
                margin: 0 0 20px 0;
            }

            .card__date {
                position: relative;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin: 0 0 20px 0;      
                
                &__many {
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }          

                p {
                    width: auto;
                    padding: 15px;
                }

                svg {
                    transform: rotate(-90deg);
                }
            }

        }

        &--square {
            .card  {
                &__container  {
                    height: auto;
                }
            }
        }

        &__container--event-v1 {
            height: auto;
        }

    }

    .card-embed-wrapper {
        .swiper-container {
            [class*=col-] {
                padding-bottom: 15px;

                &:last-child {
                    padding-top: 0;
                }
            }
        }
    }

    .card__content--category {
        align-items: baseline;
        flex-direction: column;

        .card__image-wrapper {
            width: 100%;
            height: 185px;
            min-height: inherit;
        }
        .card__content {
            margin-top: $card-categorie--margin;
            padding: $card-content--padding;
        }
    }

    .card-embed-wrapper .swiper-pagination {
        margin-top: 10px;
    }

}
