header {
    position: relative;
}

.header-top {
    background-color: $color-main;

    .header__identity {
        display: flex;
        align-items: center;
        justify-content: center;

        .identity {
            display: block;
        }

        &__title {
            display: block;
            margin: 0;
            padding: 18px 0;
            font-family: $font-family--identity;
            font-weight: $font-weight--heading;
            font-size: $font-size--1;
            line-height: $line-height;
            color: $color-second;
            text-align: center;

            &:hover {
                color: $color-second;
            }
        }
    }

}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

.tools {
    position: relative;
    display: block;
    background-color: $color-bg--neutral;

    &__content {
        position: relative;
        display: flex;
        justify-content: center;
    }

    .tool {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 10px;
        padding: 10px 5px;
        background-color: $color-bg--transparent;
        border: 0;
        font-size: $font-size--text-small;
        font-weight: $font-weight-bold;
        color: $color-link;
        text-decoration: none;
        text-transform: uppercase;
        
        svg {
            width: 26px;
            height: 26px;
            margin-right: 5px;
            fill: $color-link;
            transition: all $duration;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 5px;
            left: 0;
            width: 0;
            height: 2px;
            background-color: $color-main;
            transition: width ease-in-out $duration;
        }

        &:hover, &:focus {
            cursor: pointer;

            &::after {
                width: 100%;
                transition: width ease-in-out $duration;
            }
        }

    }

    &__search {
        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
    }

    // Google translate
    &__translate {
        position: relative;

        &__wrapper {
            position: absolute;
            left: 50%;
            top: 65px;
            z-index: 15;
            display: none;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
            width: max-content;
            padding: 15px 20px 30px;
            background-color: $color-white;
            border-radius: $border-radius;
            transform: translateX(-50%);
            filter: drop-shadow(0px 0px 1px rgba(27, 35, 54, 0.08)) drop-shadow(1px 1px 10px rgba(27, 35, 54, 0.08));
        
            &.js-open {
                display: flex;
            }

            &::before {
                content: '';
                position: absolute;
                left: 50%;
                top: -6px;
                display: block;
                width: 30px;
                height: 30px;
                background-color: $color-white;
                border-radius: 5px;
                transform: translateX(-50%) rotate(45deg);
                pointer-events: none;
                z-index: -1;
            }

            a.glink {
                position: relative;
                font-family: $font-family;
                font-size: $font-size--text;
                font-weight: $font-weight;
        
                &:hover, &:focus {
                    text-decoration: underline;
                }
        
                &.gt-current-lang {
                    font-weight: $font-weight-bold;
                    text-decoration: underline;
                }
            }
        }

        &__close {
            position: absolute;
            bottom: -10px;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(-50%);
            padding: 0;
            margin: 0;
            background-color: $color-main;
            border: 0;
            border-radius: $border-radius--round;
            transition: background-color $duration ease-in-out;

            svg {
                width: 26px;
                height: 26px;
                margin: 0;
                fill: $color-second;
                transition: fill $duration ease-in-out;
            }

            &:hover, &:focus {
                background-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }
        }
    }
}

.tools-view {
    position: absolute;
    top: 165px;
    width: 100%;
    z-index: 1;

    .container--tools-view {
        display: flex;
        justify-content: flex-end;

        // #google_translate_element {
        //     display: none;
        // }

        .profils {
            position: relative;
            display: none;
            width: 240px;
            height: auto;
            margin-top: 45px;
            margin-right: 60px;
            padding: 15px 20px 20px;
            background: $color-second;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        color: $color-white;
                        transition: all $duration;

                        &:hover {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-white;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Google translate iframe
//======================================================================================================
// iframe.goog-te-menu-frame.skiptranslate {
//     position: fixed;
//     top: 168px !important;
//     left: calc(50% + 70px) !important;
// }

// .admin-bar iframe.goog-te-menu-frame.skiptranslate {
//     top: 200px !important;
// }


//======================================================================================================
// Header Fixed
//======================================================================================================
.header.fixed .header-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 98;
    filter: drop-shadow(0px 0px 1px rgba(27, 35, 54, 0.15)) drop-shadow(1px 1px 10px rgba(27, 35, 54, 0.15));
}

.admin-bar .header.fixed .header-fixed {
    top: 32px;
}

// .js-headerfixed iframe.goog-te-menu-frame.skiptranslate {
//     top: 65px !important;
// }

// .admin-bar.js-headerfixed iframe.goog-te-menu-frame.skiptranslate {
//     top: 97px !important;
// }


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// // 1200
// @media screen and (max-width: $large) {

//     iframe.goog-te-menu-frame.skiptranslate, .admin-bar iframe.goog-te-menu-frame.skiptranslate {
//         left: calc(50% + 65px) !important;
//     }

//     iframe.goog-te-menu-frame.skiptranslate {
//         top: 235px !important;
//     }

//     .admin-bar iframe.goog-te-menu-frame.skiptranslate {
//         top: 267px !important;
//     }

// }


// 960
@media screen and (max-width: $medium) {

    .tools {
        .tool {
            span {
                display: none;
            }
    
            svg {
                width: 40px;
                height: 40px;
                margin: 0;
            }
        }
    }

    // iframe.goog-te-menu-frame.skiptranslate, .admin-bar iframe.goog-te-menu-frame.skiptranslate {
    //     position: absolute;
    //     left: calc(40% - 40px) !important;
    //     top: 317px !important;
    // }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    .header-top {
        .header__identity {
            &__title {
                font-size: 2.500rem; //40px
            }
        }
    }

    // iframe.goog-te-menu-frame.skiptranslate, .admin-bar iframe.goog-te-menu-frame.skiptranslate {
    //     position: absolute !important;
    //     left: calc(100% - (100% - 300px)/2 - 290px) !important;
    //     top: 415px !important;
    // }

    .tools-view {
        .container--tools-view {
            .profils {
                margin-top: 80px;
                margin-right: 35px;
            }
        }
    }

}
