.scrollTopBtn {
    position: fixed;
    z-index: 100;
    bottom: 20px;
    right: 20px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: none;
    margin: 0;
    padding: 0;
    opacity: 0;
    transform: translateY(100px);
    transition: all .5s ease;

    &--show,
    &--show:focus  {
        opacity: 1;
        transform: translateY(0);
        background: $color-main;
    }

    svg {

        margin-right: 0;
        
        path {
            fill: $color-second;
            transition: $duration;
        }
    }

    &:hover {
        background: $color-dark;

        svg {
            path {
                fill: $color-white;
            }
        }
    }
  }