.breadcrumb {
    background-color: $color-bg--neutral;

    ul  {
        display: block;
        min-height: 60px;
        padding: 18px 0;
    }

    li {
        position: relative;
        display: inline;
        margin-right: 20px;
        padding: 0 20px 0 0;
        color: $color-text;
        line-height: 1.75;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 75%;
            background-color: $color-main;
            border-radius: 50px;
            transform: translateY(-50%);
            opacity: 1;
            transition: opacity $duration ease-in-out;
        }

        &:first-child { 
            padding-left: 0;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        a {
            &:hover, &:focus {
                text-decoration: underline;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        li {
            margin-right: 10px;
            padding: 0 10px 0 0;
        } 
    }

}
